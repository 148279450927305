import { clarity } from 'clarity-js';

export const startTracking = (user) => {
  try {
    clarity.consent();
    if (user?.email) {
      const { userId, email, userName } = user;

      // setCookie('user_id', userId, 1);
      // setCookie('user_email', email, 1);
      // setCookie('user_name', userName, 1);
      clarity.set('userId', userId);
      clarity.set('user_email', email);
      clarity.set('user_name', userName);
      clarity.identify(userId);

      console.log('Starting clarity tracking', process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID);

      clarity.start({
        projectId: process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
        // cookies: ['user_id', 'user_email', 'user_name'],
      });
    }
  } catch (error) {
    console.error('Error while starting clarity tracking', error);
  }
};

export const stopTracking = () => {
  try {
    console.log('Stopping clarity tracking');

    clarity.stop();
  } catch (error) {
    console.error('Error while stopping clarity tracking', error);
  }
};
