import { gql } from '@apollo/client';

export const GET_TODOS = gql`
  query GET_TODOS($page: Int, $size: Int, $user_id: String!) {
    getAllToDo(page: $page, size: $size, user_id: $user_id) {
      pageInfo {
        totalItems
        currentPage
        size
      }
      nodes {
        todoUrl
        user_id
        todoType
        action
        nextAction
        referenceId
        status
        updatedAt
        createdAt
        object_id
        additionalRemarks
        warehouseId
      }
    }
  }
`;

export const GET_ROLES = gql`
  query GET_ROLES(
    $country: String
    $page: Int
    $size: Int
    $sortBy: String
    $sortOrder: String
    $includePermissions: Boolean
    $searchQuery: String
  ) {
    getRoles(
      country: $country
      page: $page
      size: $size
      sortBy: $sortBy
      sortOrder: $sortOrder
      includePermissions: $includePermissions
      searchQuery: $searchQuery
    ) {
      nodes {
        roleId
        roleName
        country
        startTime
        endTime
        restrictLogin
        Permissions {
          permissionId
          permissionName
        }
      }
      pageInfo {
          currentPage
          size
          totalItems
          hasNextPage
          hasPreviousPage
        }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query GET_PERMISSIONS($country: String, $page: Int, $size: Int, $searchQuery: String) {
    getPermissions(
      country: $country
      page: $page
      size: $size
      searchQuery: $searchQuery
    ) {
      nodes {
        permissionId
        permissionName
        Roles {
          roleId
          roleName
        }
      }
      pageInfo {
        totalItems
        currentPage
        size
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query GET_USER_ROLES($sellerId: String, $country: String) {
    getRolesForUser(sellerId: $sellerId, country: $country) {
      nodes {
        roleId
        roleName
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query GET_WAREHOUSES {
    getWarehouses {
      warehouseId
      warehouseName
      cityId
      lat
      long
      isActive
      isDepot
      isSapEnabled
      City {
        cityName
        cityId
        State {
          stateId
          stateName
        }
      }
      Distributor {
        distributorId
        distributorName
        distributorType
        address
        contactName
        email
        phoneNumber
        bvn
        cacNumber
        logo
        verticalId
        vertical
        Warehouses {
          warehouseId
          warehouseName
        }
        cityId
        City {
          cityName
          cityId
          State {
            stateId
            stateName
          }
        }
        isActive
        paymentType
        categoryIds
        categories
        registrationPrefix
        creditTerm
      }
      usesOptimizedDelivery
    }
  }
`;

export const GET_STATES_CITIES = gql`
  query GET_STATES_CITIES {
    getStateAndCities {
      stateId
      stateName
      Cities {
        cityId
        cityName
        Landmarks {
          landmarkName
          landmarkId
        }
      }
    }
  }
`;

export const GET_SELLERS = gql`
  query GET_SELLERS($size: Int, $warehouseId: String, $stateId: String) {
    getSellers(size: $size, warehouseId: $warehouseId, stateId: $stateId) {
      nodes {
        _id
        sellerName
        status
        warehouses
        warehouseList {
          warehouseId
          warehouseName
        }
        pickupLocations {
          warehouse
          _id
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GET_ALL_PRODUCTS($getInActive: Boolean) {
    getAllProducts(getInActive: $getInActive) {
      nodes {
        _id
        category
        subCategory
        vertical
        brand
        product_SKU
        variantName
        displayTitle
        productName
        isActive
        description
        minWholeSaleQty
        stockValue
        images {
          imageUrl
        }
        pricing {
          wholesalePrice
          retailPrice
        }
        isActive
      }
    }
  }
`;

export const GET_VARIANTS = gql`
  query GET_ALL_VARIANTS {
    getProducts(page: 1, size: -1, getInActive: false) {
      pageInfo {
        currentPage
        size
        totalItems
      }
      nodes {
        _id
        variantName
        displayTitle
        product_SKU
        brand
        category
        subCategory
        pricing {
          wholesalePrice
          retailPrice
        }
      }
    }
  }
`;

export const GET_COUNTRY_CONFIG = gql`
  query GET_COUNTRY_CONFIG($countryCode: String) {
    getCountryConfig(countryCode: $countryCode, includeOrder: true) {
      countryName
      countryCode
      countryPhoneCode
      baseUrl
      currencySymbol
      currency
      flagUrl
      phoneValidation
      samplePhone
      orderConfig
      storeTypes
      segments
    }
  }
`;

export const GET_COUNTRY_URL = gql`
  query GET_COUNTRY_URL($countryCode: String) {
    getCountryUrl(countryCode: $countryCode)
  }
`;
export const GET_COUPON_STATS = gql`
  query GET_COUPON_STATS($couponCode: String!) {
    getCouponStats(couponCode: $couponCode) {
      ordersPlacedCount
      lastOrderPlacedDate
      ordersFulfilledCount
      lastOrderFulfilledDate
    }
  }
`;

export const GET_APP_INSTALLED = gql`
  query GET_APP_INSTALLED($couponCode: String!) {
    getCustomersWithCouponCount(couponCode: $couponCode) {
      customerCount
      lastCustomerCreatedDate
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CREATE_ROLE($roleName: String, $permissions: [String]) {
    createRole(roleName: $roleName, permissions: $permissions) {
      roleId
      roleName
      country
      Permissions {
        permissionId
        permissionName
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UPDATE_ROLE($roleId: String, $roleName: String, $permissions: [String]) {
    updateRole(roleId: $roleId, roleName: $roleName, permissions: $permissions) {
      roleId
      roleName
      country
      Permissions {
        permissionId
        permissionName
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DELETE_ROLE($roleId: String) {
    deleteRole(roleId: $roleId)
  }
`;

export const CREATE_PERMISSION = gql`
  mutation CREATE_PERMISSION($permissionName: String) {
    createPermission(permissionName: $permissionName) {
      permissionId
      permissionName
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation UPDATE_PERMISSION($permissionId: String, $permissionName: String) {
    updatePermission(permissionId: $permissionId, permissionName: $permissionName) {
      permissionId
      permissionName
    }
  }
`;

export const DELETE_PERMISSION = gql`
  mutation DELETE_PERMISSION($permissionId: String) {
    deletePermission(permissionId: $permissionId)
  }
`;
