import { createContext, useState, useCallback } from 'react';

export const BackOrderContext = createContext({});

export default function BackOrderProvider({ children }) {
  const [backOrderInfo, setBackOrderInfo] = useState({
    selectAllOrders: false,
    orders: [],
  });
  const addOrder = (order, fees) => {
    setBackOrderInfo((currentInfo) => {
      const orders = currentInfo.orders || [];
      const newOrders = orders.concat([order]);

      return {
        ...currentInfo,
        orders: newOrders,
        selectAllOrders: newOrders.length === fees.length,
      };
    });
  };

  const removeOrder = (order, fees) => {
    setBackOrderInfo((currentInfo) => {
      const orders = currentInfo.orders || [];
      const newOrders = orders.filter((o) => o.productId !== order.productId);

      return {
        ...currentInfo,
        orders: newOrders,
        selectAllOrders: newOrders.length === fees.length,
      };
    });
  };

  const getBackOrderItems = useCallback(
    () => Object.values(backOrderInfo.orders),
    [backOrderInfo],
  );

  return (
    <BackOrderContext.Provider
      value={{
        backOrderInfo,
        getBackOrderItems,
        setBackOrderInfo,
        addOrder,
        removeOrder,
      }}
    >
      {children}
    </BackOrderContext.Provider>
  );
}
