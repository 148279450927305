export const envs = Object.freeze({
  DEV: 'development',
  PROD: 'production',
});

export const USER_ID = 'USER_ID';
export const AUTH_TOKEN = 'AUTH_TOKEN';

export const colors = ['#E06055', '#96AFC1', '#688EC7', '#B696C1', '#C1A696', '#AED582', '#68C7BC', '#90A4AD', '#F06292'];
export const tabs = ['Inventory', 'Sellers', 'Purchase Requisition', 'Purchase Order', 'Procurement', 'Transfers', 'Incidents', 'Orders', 'In-store Transactions', 'Team', 'Vehicles', 'Warehouse Info'];
export const PAGE_SIZE = 50;

export const GQL_FETCH_POLICIES = Object.freeze({
  NETWORK: 'network-only',
  CACHE_NETWORK: 'cache-and-network',
  CACHE: 'cache-first',
});

export const FEATURES = Object.freeze({
  USERS_LIST: 'users-list',
  CUSTOMERS_LIST: 'customers-list',
  PRODUCTS_LIST: 'products-list',
  ORDERS_LIST: 'orders-list',
  WAREHOUSES_LIST: 'warehouses-list',
  PROCUREMENT_LIST: 'procurement-list',
  ROUTE_LIST: 'route-list',
});

export const INPUT_PATTERNS = Object.freeze({
  NUMBER_ONLY: /^\d*\.?\d{0,2}$/,
  SPECIAL_CHARACTERS: /^[A-Za-z\d/\s-]*$/,
  CHARACTERS_ONLY: /^[A-Za-z]{0,3}$/,
  VEHICLE_NUMBER: /^[A-Za-z]{3}\d{2,3}[A-Za-z]{2}$/,
});

export const orderEndedStatuses = [
  'order_delivered', 'order_delivery_with_returns', 'order_delivery_return_approved', 'order_delivery_return_verified', 'order_delivery_return_accepted',
  'order_cancelled', 'order_undelivered', 'order_undelivered_approved', 'order_undelivered_verified', 'order_undelivered_accepted',
];

export const reasonChangeStatuses = [
  { label: 'Undelivered', value: 'order_undelivered' },
  { label: 'Cancelled', value: 'order_cancelled' },
  { label: 'Delivered With Returns', value: 'order_delivery_with_returns' },
  { label: 'Order Returned', value: 'order_full_return' },
];

export const orderSourceMapping = [
  { label: 'ALERZOSHOP APP', value: 'ALERZOSHOP_APP' },
  { label: 'ALERZOSHOP PWA', value: 'ALERZOSHOP_PWA' },
  { label: 'ADMIN APP', value: 'ADMIN_APP' },
  { label: 'ADMIN WEB', value: 'ADMIN_WEB' },
];

export const segmentMapping = [
  { label: 'All Customer', value: '*' },
  { label: 'Dormant User', value: 'Dormant User' },
  { label: 'Active Customer', value: 'Active Customer' },
  { label: 'Inactive Customer', value: 'Inactive Customer' },
  { label: 'Dormant Customer', value: 'Dormant Customer' },
  { label: 'Churned Customer', value: 'Churned Customer' },
  { label: 'AZ Dormant User', value: 'AZ Dormant User' },
  { label: 'AZ Active Customer', value: 'AZ Active Customer' },
  { label: 'AZ Inactive Customer', value: 'AZ Inactive Customer' },
  { label: 'AZ Dormant Customer', value: 'AZ Dormant Customer' },
  { label: 'AZ Churned Customer', value: 'AZ Churned Customer' },
];

export const PORTAL = process.env.REACT_APP_PORTAL || 'admin';
export const isAlerzoUser = PORTAL !== 'seller';
export const PLATFORM = process.env.REACT_APP_PLATFORM || 'ADMIN_WEB';
export const DEFAULT_SELLER_ID = '5d6ede6a0ba62570afcedd3a';
export const DEFAULT_NIGERIA_COORDS = { lat: 9.076479, lng: 7.398574 };
export const DEFAULT_UGANDA_COORDS = { lat: 0.313611, lng: 32.581111 };

export const isNigeria = process.env.REACT_APP_COUNTRY_CODE === 'NG';
export const APP_NAME = isNigeria ? 'Alerzo' : 'SK Express';
export const supervisorLabel = isNigeria ? 'Inventory Supervisor' : 'Warehouse Analyst';

export const vehicleRepairCategories = [
  { label: 'Select Category of Repair', value: '' },
  { label: 'Engine Components', value: 'engine_components' },
  { label: 'Suspension And Steering Systems', value: 'suspension_and_steering_systems' },
  { label: 'Braking System', value: 'braking_system' },
  { label: 'Engine Cooling System', value: 'engine_cooling_system' },
  { label: 'Electrical', value: 'electrical' },
  { label: 'Tyres', value: 'tyres' },
  { label: 'Exhaust System', value: 'exhaust_system' },
  { label: 'Fuel Supply System', value: 'fuel_supply_system' },
  { label: 'Engine Oil System', value: 'engine_oil_systems' },
  { label: 'Transmission System', value: 'transmission_system' },
  { label: 'Body Work', value: 'body_work' },
  { label: 'Bearings', value: 'bearings' },
  { label: 'Hose', value: 'hose' },
];

export const vehicleMaintenanceCategories = [
  { label: 'Select Parts Serviced', value: '' },
  { label: 'Brake Oil', value: 'brake_oil' },
  { label: 'Engine Oil', value: 'engine_oil' },
];
