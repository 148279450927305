// Core Packages
import classnames from 'classnames';

// Material
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

// Styles
import styles from './style.module.css';

export default function StyledDialog({
  label,
  maxWidth = 'sm',
  onClose,
  className,
  containerClassName,
  children,
  hideCloseButton = false,
  disableBackdropClick = true,
  fullWidth = true,
  showTitle = false,
  message,
  noPadding = false,
  noTopPadding = false,
}) {
  const dialogClasses = classnames('styled-dialog', styles.dialog, className);
  const containerClasses = classnames(
    styles.container, containerClassName, noPadding ? styles.noPadding : styles.padding, noTopPadding ? styles.noTopPadding : '',
  );

  return (
    <Dialog
      data-test="component-styled-dialog"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby={label}
      open
      onClose={onClose}
      className={dialogClasses}
      disableBackdropClick={disableBackdropClick}
    >
      {showTitle && <DialogTitle>{label}</DialogTitle>}
      {message && <p className={styles.message}>{message}</p>}
      <DialogContent className={containerClasses}>
        {!hideCloseButton && (
          <IconButton
            data-test="component-styled-dialog-close"
            className={styles.closeBtn}
            aria-label="Close"
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}
