import * as Sentry from '@sentry/browser';

import { Integrations as TracingIntegrations } from '@sentry/tracing';

const isDev = process.env.REACT_APP_ENV === 'development';

if (!isDev) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new TracingIntegrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV || 'development',
  });
}
