import { envs, orderSourceMapping } from './constants';

export const getEnvDetails = () => {
  const env = process.env.NODE_ENV;

  return {
    isDev: env === envs.DEV,
    isProd: env === envs.PROD,
  };
};

export const convertToFittingDataType = (value) => {
  if (!value) return value;
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value.match(/^[+-]?[0-9]+$/)) return parseInt(value, 10);
  if (value.match(/^[+-]?\d+(\.\d+)?$/)) return parseFloat(value);
  return value;
};

export const getNameInitials = (name = '') => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...(name || '').matchAll(rgx)] || [];

  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

  return initials;
};

export const getRandomItemFromArray = (arr = []) => arr[Math.floor(Math.random() * arr.length)];

export const getUniqueValuesFromArray = (arr = []) => [...new Set(arr)];

export const getSortOptions = (sortField) => {
  const sortParts = sortField.split('_');
  const sortOrder = sortParts[0];
  const sortBy = sortParts.slice(1).join('_');
  return [sortOrder, sortBy];
};

export const createRolesLabel = (roles) => {
  const firstTwo = roles
    .filter((_role, index) => index < 2)
    .map((role) => role?.roleName)
    .join(', ');
  const others = roles.length > 2 ? ` and ${roles.length - 2} more` : '';
  return `${firstTwo}${others}`;
};

export const normalizeData = (itemsArray = [], normalizingKey = '_id') => {
  console.log(normalizingKey);
  return itemsArray.reduce(
    (normalizedData, item) => ({
      ...normalizedData,
      [item[normalizingKey]]: item,
    }),
    {},
  );
};

export const createDropdownOptionsWithSameValue = (value) => ({ value, label: value });

export const truncate = (string = '', length = 20) => (string.length > length ? `${string.substring(0, length)}...` : string);

export const formatPhone = (phone = '') => {
  let formattedPhone = phone.replace('+234', '0');
  if (formattedPhone[0] !== 0 && formattedPhone.length === 10) {
    formattedPhone = `0${formattedPhone}`;
  }
  return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7)}`;
};

export const isPhoneInvalid = (phone = '') => {
  const isPhoneLengthInvalid = phone.length !== (phone.startsWith('0') ? 11 : 10);
  return isPhoneLengthInvalid || !/^[0-9\b]+$/.test(phone);
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const getVehiclesDataMapping = (vehiclesData = []) => {
  const map = {};
  vehiclesData.forEach(({ model, registration }) => {
    map[model] = [...(map[model] || []), registration];
  });
  return map;
};

export const formatQuantity = (quantity) => quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatAmount = (amount, toWholeNumber) => {
  if (Number.isNaN(amount)) return '';
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let formattedAmount = (+amount || 0).toFixed(3).toString();
  if (!formattedAmount.includes('.')) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseJWT = (token) => {
  if (!token) {
    return { permissions: [] };
  }
  const [, base64Url] = token.split('.');
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const differenceInArrByKey = (
  arr1, arr2, key,
) => arr2.filter((obj1) => !arr2.find((obj) => obj[key] === obj1[key]));

export const getOrderStatusColor = (status, statusLabel = '') => {
  let variant = 'info';
  if (status?.toLowerCase() === 'cancelled') {
    variant = 'error';
  } else if (status?.toLowerCase() === 'processing') {
    variant = 'warn';
  } else if (status?.toLowerCase() === 'delivered' || status?.toLowerCase() === 'returned' || status?.toLowerCase() === 'verified') {
    variant = 'success';
  } else if (status?.toLowerCase() === 'undelivered') {
    variant = 'warn2';
  } else if (status?.toLowerCase() === 'unvalidated') {
    variant = 'pending';
  } else if (status?.toLowerCase() === 'archived') {
    variant = 'inactive';
  } else if (status?.toLowerCase() === 'pending') {
    variant = 'pending';
  } else if (status?.toLowerCase() === 'pending_cancellation') {
    variant = 'warn';
  } else if (status?.toLowerCase() === 'completed') {
    variant = 'success';
  }

  if (statusLabel === 'Archived') {
    variant = 'inactive';
  }

  return variant;
};

export const getVehicleStatusColor = (status) => {
  let variant = 'info';
  if (['assigning', 'assigned', 'prepared', 'dispatched'].includes(status)) {
    variant = 'warn';
  } else if (status === 'available') {
    variant = 'success';
  } else if (status === 'unavailable') {
    variant = 'inactive';
  } else if (status === 'cancelled') {
    variant = 'error';
  }

  return variant;
};

export const getTripStatusColor = (status) => {
  let variant = 'info';
  if (status === 'unassigned') {
    variant = 'warn';
  } else if (status === 'completed') {
    variant = 'success';
  } else if (status === 'active') {
    variant = 'info';
  } else if (status === 'cancelled') {
    variant = 'error';
  }

  return variant;
};

export const getSellerStatusColor = (status) => {
  let variant = 'info';
  if (status === 'REQUESTED') {
    variant = 'warn';
  } else if (status === 'APPROVED' || status === 'ACTIVE' || status === 'CREATED') {
    variant = 'success';
  } else if (status === 'INACTIVE') {
    variant = 'inactive';
  } else if (status === 'DECLINED') {
    variant = 'error';
  }

  return variant;
};

export const getTransactionStatusColor = (status) => {
  let variant = 'info';
  if (['transaction_pending', 'transaction_pending_returned_approval'].includes(status)) {
    variant = 'warn';
  } else if (['transaction_approved', 'transaction_returned'].includes(status)) {
    variant = 'success';
  }

  return variant;
};

export const getVehicleRequestsStatusColor = (status) => {
  let variant = 'info';
  if (['pending'].includes(status)) {
    variant = 'warn';
  } else if (['approved', 'completed', 'in_progress', 'paid'].includes(status)) {
    variant = 'success';
  } else if (status === 'draft' || status === 'in_review') {
    variant = 'inactive';
  } else if (status === 'declined' || status === 'cancelled') {
    variant = 'error';
  }

  return variant;
};

export const getCustomerStatusColor = (status) => {
  let variant = 'info';
  if (status === 'ACTIVE') {
    variant = 'success';
  } else if (status === 'INACTIVE') {
    variant = 'inactive';
  } else if (status === 'DRAFT') {
    variant = 'warn';
  }

  return variant;
};

export const transactionMessageMap = {
  transaction_pending: 'Waiting for Stock Prepare',
  transaction_prepared: 'Waiting for Stock Verification',
  transaction_verified: 'Waiting for Stock Approval',
  transaction_approved: 'Waiting for Stock Dispatch',
  transaction_committed: 'Waiting for Stock Return',
  transaction_dispatched: 'Waiting for Stock Return',
  transaction_pending_returned_approval: 'Waiting for Return Approval',
};

export const sellerStatuses = [
  { label: 'All Status', value: 'all' },
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Declined', value: 'DECLINED' },
  { label: 'Deleted', value: 'DELETED' },
];

export const vehicleStatus = [
  { label: 'All Status', value: 'all' },
  { label: 'Available', value: 'available' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Dispatched', value: 'dispatched' },
  { label: 'Unavailable', value: 'unavailable' },
];
export const vehicleRequestStatuses = [
  { label: 'All Status', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Paid', value: 'paid' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Declined', value: 'declined' },
  { label: 'Completed', value: 'completed' },
];

export const vehicleRequestStatusMap = {
  pending: 'pending',
  in_review: 'draft',
  approved: 'approved',
  in_progress: 'in_progress',
  declined: 'declined',
  completed: 'completed',
  paid: 'paid',
  cancelled: 'cancelled',
};

export const vehicleRequestMessageMap = {
  pending: 'Waiting for approval',
  in_review: 'Waiting for submit',
  paid: 'Waiting to proceed',
  in_progress: 'Waiting for completion',
  approved: 'Waiting for payment',
};

export const purchaseRequisitionStatusMap = {
  pending: 'warn',
  approved: 'success',
  draft: 'inactive',
  declined: 'error',
  's-approved': 'success',
  's-pending': 'warn',
  rt_dispatched: 'success',
  dispatched: 'success',
  'picked-up': 'success',
  'wh-arrived': 'success',
};

export const purchaseRequisitionMessageMap = {
  approved: 'Waiting for Seller Approval',
  's-approved': 'Waiting for Assign Vehicle',
  rt_dispatched: 'Waiting for Dispatch',
  dispatched: 'Waiting for Picked Up',
  'picked-up': 'Waiting for Warehouse Arrival',
};

export const formatDate = (date, st = true) => {
  const d = new Date(date);
  let dd;
  if (st) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    dd = [day, month, year].join(' ');
  } else {
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const year = d.getFullYear();
    dd = [year, month, day].join('-');
  }
  return dd;
};

export const addOneDayToDate = (date, format = true) => {
  const newDate = new Date(date);
  const currentDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  currentDate.setDate(currentDate.getDate() + 1);
  if (newDate > currentDate) {
    return format ? formatDate(currentDate) : currentDate;
  }
  return format ? formatDate(newDate) : newDate;
};

export const getStartAndEndDate = (daysBack, options) => {
  const { addOneDayToEndDate = false, format = false } = options || {};
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - Number(daysBack));
  endDate.setDate(endDate.getDate() + (addOneDayToEndDate ? 1 : 0));
  if (!format) {
    return {
      startDate: new Date(startDate).toISOString().split('T')[0],
      endDate: new Date(endDate).toISOString().split('T')[0],
    };
  }
  return {
    startDate: formatDate(new Date(startDate).toISOString().split('T')[0]),
    endDate: formatDate(new Date(endDate).toISOString().split('T')[0]),
  };
};

export const downloadURL = (uri, name) => {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeDuplicatesInObjArr = (arr, uniqueIdentifier) => {
  const seen = new Set();
  return arr.filter((obj) => {
    const duplicate = seen.has(obj[uniqueIdentifier]);
    seen.add(obj[uniqueIdentifier]);
    return !duplicate;
  });
};

export const removeDuplicatesInArrOfArr = (arr) => {
  const stringArray = arr.map(JSON.stringify);
  const uniqueStringArray = new Set(stringArray);
  const uniqueArray = Array.from(uniqueStringArray, JSON.parse);
  return uniqueArray;
};

export const sortArrOfObj = (arr, sortKey) => {
  function compare(a, b) {
    if (a[sortKey]?.toLowerCase() < b[sortKey]?.toLowerCase()) {
      return -1;
    }
    if (a[sortKey]?.toLowerCase() > b[sortKey]?.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  return [...arr]?.sort(compare);
};

export const camelize = (str) => {
  if (str) {
    const letters = str[0].toUpperCase() + str.substring(1);
    return letters
      .replace(/_([a-zA-Z])/g, (m1, m2) => m2.toUpperCase())
      .replace(/([A-Z])/g, ' $1')
      .trim();
  }
  return '';
};

export const dateDiff = (date, endDate = null) => {
  let currentDate = endDate;

  if (!currentDate) {
    currentDate = new Date();
  }

  return currentDate.getTime() - new Date(date).getTime();
};

export const diffLessThanOneMinute = (date) => {
  try {
    const diff = dateDiff(date);

    const minutesDifferent = Math.floor(diff / (1000 * 60));

    return minutesDifferent === 0;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const getPlatformLabel = (platformValue) => {
  const platform = orderSourceMapping.find((platform) => platform.value === platformValue);

  return platform ? platform.label : '-';
};

export const arrayToObjectMap = (arr, key) => arr.reduce((ac, a) => ({ ...ac, [a[key]]: a }), {});

export const removeSmallLettersFromString = (value) => (value ? value.replace(/[a-z]/g, '').replace(/\s+/g, '') : '');

export const vehicleSortValues = [
  { label: 'All', value: 'all' },
  { label: 'Missing Documents', value: 'missing_docs' },
  { label: 'Missing Accessories', value: 'missing_accs' },
  { label: 'Expired Documents', value: 'expired_docs' },
  { label: 'Expired Accessories', value: 'expired_accs' },
];

export const getBDEFormStatusColor = (status) => {
  let variant = 'info';
  if (status === 'pending') {
    variant = 'warn';
  } else if (status === 'approved') {
    variant = 'success';
  } else if (status === 'declined') {
    variant = 'error';
  }

  return variant;
};

export const replaceWithUnderscore = (str) => str.toLowerCase().replace(/ /g, '_');

export const getDeliveryStatusColor = (status) => {
  let variant = 'info';
  if (status === 'ACTIVE') {
    variant = 'success';
  } else if (status === 'INACTIVE') {
    variant = 'error';
  } else {
    variant = 'info';
  }

  return variant;
};

export const terminalStatuses = [
  { label: 'All Status', value: 'all' },
  { label: 'Unassigned', value: 'unassigned' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const terminalRequestStatuses = [
  { label: 'All Status', value: 'all' },
  { label: 'Requested', value: 'requested' },
  { label: 'Approved', value: 'approved' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Dispatched', value: 'dispatched' },
  { label: 'Delivered', value: 'delivered' },
];

export const timeLimits = [
  { label: 'All Days', value: 'all' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

export const posVariants = [
  { label: 'All Variants', value: 'all' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

export const getTerminalStatusColor = (status) => {
  let variant = 'info';
  if (status === 'unassigned') {
    variant = 'inactive';
  } else if (status === 'assigned') {
    variant = 'success';
  }

  return variant;
};

export const getTerminalRequestStatusColor = (status) => {
  let variant = 'info';
  if (status === 'requested') {
    variant = 'pending';
  } else if (status === 'approved') {
    variant = 'primary-alt';
  } else if (status === 'assigned') {
    variant = 'pending';
  } else if (status === 'rejected') {
    variant = 'error';
  } else if (status === 'dispatched') {
    variant = 'info';
  } else if (status === 'delivered') {
    variant = 'success';
  }

  return variant;
};

export const getTerminalTransactionStatusColor = (statusCode) => {
  let variant = 'info';
  if (statusCode === 200) {
    variant = 'success';
  } else {
    variant = 'error';
  }

  return variant;
};

export const scrollElementByRef = (scrollOffset, ref) => {
  ref.current.classList.add('scroll-smooth');
  // eslint-disable-next-line no-param-reassign
  ref.current.scrollLeft += scrollOffset;
  ref.current.classList.remove('scroll-smooth');
};

export const formatNumberToNaira = (number) => new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  maximumFractionDigits: 0,
}).format(Number(number));

export const tripStatus = [
  { label: 'All Status', value: 'all' },
  { label: 'Unassigned', value: 'unassigned' },
  { label: 'Active', value: 'active' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
];

export const defaultStatus = [
  { label: 'All Status', value: 'all' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

export const couponStatus = [
  { label: 'All Status', value: 'all' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];
