// Core Packages
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

// Custom Components
import StyledDialog from '../Dialog';
import StyledButton from '../Button';
import { WarningIcon } from '../../pages/dashboard/icons';

// Styles
import styles from './style.module.css';

export default function ErrorDialog({
  onClose,
  hideCloseButton = false,
  className,
  label,
  reload,
  children = null,
  disableBackdropClick = false,
}) {
  const history = useHistory();
  const classes = classnames('xs', className);

  return (
    <StyledDialog
      maxWidth="xs"
      data-test="component-error-dialog"
      hideCloseButton={hideCloseButton}
      onClose={onClose}
      className={classes}
      disableBackdropClick={disableBackdropClick}
    >
      <div className={styles.customersUploadContainer}>
        <WarningIcon className={styles.icon} />
        <span className={styles.title}>
          Error
        </span>
        <span className={styles.description}>
          {label}
        </span>
        {reload && (
          <StyledButton
            onClick={() => history.go(0)}
          >
            Reload
          </StyledButton>
        )}
        {children}
      </div>
    </StyledDialog>
  );
}
