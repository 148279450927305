// Material
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom Components
import StyledDialog from '../Dialog';

// Styles
import styles from './style.module.css';

export default function ProgressDialog({ label, hideCloseButton, size = 'xs' }) {
  return (
    <StyledDialog
      data-test="component-progress-dialog"
      className={size}
      hideCloseButton={hideCloseButton}
    >
      <div className={styles.uploadingContainer}>
        <CircularProgress
          data-test="component-progress-dialog-animation"
          className={styles.uploadingIcon}
          size={40}
          thickness={3}
        />
        <span className={styles.uploadingTitle}>{label}</span>
      </div>
    </StyledDialog>
  );
}
