import { useState, createContext } from 'react';
import ErrorDialog from '../components/ErrorDialog';

export const ErrorDialogContext = createContext({});

export default function ErrorDialogContainer({ children }) {
  const DEFAULT_DIALOG_PROPS = {
    hideCloseButton: true,
    reload: false,
  };

  const [isDialogVisible, setDialogVisibility] = useState(false);
  const [dialogProps, setDialogProps] = useState(DEFAULT_DIALOG_PROPS);

  const openErrorDialog = (providedProps) => {
    setDialogProps({ ...DEFAULT_DIALOG_PROPS, ...providedProps });
    setDialogVisibility(true);
  };

  const closeErrorDialog = () => setDialogVisibility(false);

  return (
    <ErrorDialogContext.Provider value={{ openErrorDialog, closeErrorDialog }}>
      {isDialogVisible && (
        <ErrorDialog
          {...dialogProps}
          onClose={closeErrorDialog}
        />
      )}

      {children}
    </ErrorDialogContext.Provider>
  );
}
