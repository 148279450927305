// Core Packages
import classnames from 'classnames';

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import styles from './style.module.css';

export default function StyledButton({
  onClick,
  type = 'button',
  className,
  variant = 'contained',
  disabled = false,
  children = null,
  color = 'primary',
  icon,
  fullWidth = false,
  size,
  marginRight,
  showLoading,
}) {
  const classes = classnames(className, styles.styledBtn, { [styles.marginRight]: marginRight });
  const Icon = icon || null;
  return (
    <Button
      data-test="component-styled-button"
      className={classes}
      color={color}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    >
      {icon && <Icon className={styles.icon} />}
      {children}
      {showLoading && <CircularProgress className={styles.loader} color="inherit" size="1rem" />}
    </Button>
  );
}
