// Core Packages
import { BrowserRouter } from 'react-router-dom';

// Utils
import { ErrorBoundary } from 'react-error-boundary';
import Routes from './routes';
import ProgressDialogProvider from './contexts/ProgressDialog';
import ErrorDialogProvider from './contexts/ErrorDialog';
import NotificationProvider from './contexts/Notification';
import ErrorFallback from './ErrorFallback';
import BackOrderProvider from './contexts/BackOrders';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ProgressDialogProvider>
          <ErrorDialogProvider>
            <NotificationProvider>
              <BackOrderProvider>
                <Routes />
              </BackOrderProvider>
            </NotificationProvider>
          </ErrorDialogProvider>
        </ProgressDialogProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
