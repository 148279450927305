// Core Packages
import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Material
import CircularProgress from '@material-ui/core/CircularProgress';

// Utils
import { getItem, saveItem } from '../services/storage';
import { GQL_FETCH_POLICIES, USER_ID } from '../utils/constants';
import { routes } from './constants';

// Styles
import styles from './style.module.css';
import { GET_COUNTRY_URL } from '../graphql/dashboard/common';

// Custom Components
const Auth = lazy(() => import('../pages/auth'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const NotFound = lazy(() => import('../pages/NotFound'));

const Loader = () => (
  <div className={styles.loader}>
    <CircularProgress />
  </div>
);

function AuthContainer() {
  const isAuthenticated = getItem(USER_ID);

  return isAuthenticated ? (
    <Redirect to={routes.DASHBOARD} />
  ) : (
    <Auth />
  );
}

function DashboardContainer() {
  const isAuthenticated = getItem(USER_ID);

  return isAuthenticated ? (
    <Dashboard />
  ) : (
    <Redirect to={routes.AUTH} />
  );
}

export default function Routes() {
  const { data } = useQuery(
    GET_COUNTRY_URL, {
      fetchPolicy: GQL_FETCH_POLICIES.CACHE_NETWORK,
      variables: { countryCode: process.env.REACT_APP_COUNTRY_CODE || 'NG' },
    },
  );
  useEffect(() => {
    if (data?.getCountryUrl) {
      saveItem('APP_GRAPHQL_URI', data?.getCountryUrl);
    }
  }, [data]);
  if (data?.getCountryUrl) {
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={routes.DASHBOARD}>
            <DashboardContainer />
          </Route>
          <Route path={routes.AUTH}>
            <AuthContainer />
          </Route>
          <Redirect exact path="/" to={routes.DASHBOARD} />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    );
  } return <Loader />;
}
