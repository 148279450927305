// Core Packages
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';

import reportWebVitals from './reportWebVitals';
import { getEnvDetails } from './utils/helpers';
import theme from './theme';
import client from './graphql';
import App from './App';
import './firebase';
import './sentry';

// Styles
import './index.css';
import './global.css';
import './overrides.css';

const root = document.getElementById('root');

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  root,
);

const { isDev } = getEnvDetails();
if (isDev) {
  reportWebVitals();
}
