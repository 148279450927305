import { useState, createContext } from 'react';
import ProgressDialog from '../components/ProgressDialog';

export const ProgressDialogContext = createContext({});

export default function ProgressDialogContainer({ children }) {
  const DEFAULT_DIALOG_PROPS = {
    label: 'Loading...',
    size: 'xs',
    hideCloseButton: true,
  };

  const [isDialogVisible, setDialogVisibility] = useState(false);
  const [dialogProps, setDialogProps] = useState(DEFAULT_DIALOG_PROPS);

  const openProgressDialog = (providedProps) => {
    setDialogProps({ ...DEFAULT_DIALOG_PROPS, ...providedProps });
    setDialogVisibility(true);
  };

  const closeProgressDialog = () => setDialogVisibility(false);

  return (
    <ProgressDialogContext.Provider value={{ openProgressDialog, closeProgressDialog }}>
      {isDialogVisible && (
        <ProgressDialog
          {...dialogProps}
        />
      )}

      {children}
    </ProgressDialogContext.Provider>
  );
}
