/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app';
import { clearStorage, saveItem } from './storage';
import { routes } from '../routes/constants';
import { AUTH_TOKEN } from '../utils/constants';
// eslint-disable-next-line no-unused-vars
import { resetProperties } from '../utils/analytics';
import { stopTracking } from '../utils/microsoft-clarity';

export const logout = () => {
  firebase.auth().signOut();
  clearStorage();
  resetProperties();
  stopTracking();
  window.location.href = routes.AUTH;
};

export const loginWithCustomToken = (customToken) => new Promise((resolve, reject) => {
  firebase
    .auth()
    .signInWithCustomToken(customToken)
    .then(({ user }) => resolve(user))
    .catch(reject);
});

export const getCurrentUser = () => new Promise((resolve) => {
  const user = firebase.auth().currentUser;
  if (user) {
    resolve(user);
  } else {
    const unsubscribeFromAuthListener = firebase.auth().onAuthStateChanged(resolve);
    window.addEventListener('beforeunload', unsubscribeFromAuthListener);
  }
});

export const generateIdToken = () => new Promise((resolve, reject) => {
  const processToken = (idToken) => {
    const token = idToken.toString();
    saveItem(AUTH_TOKEN, token);
    resolve(token);
    return token;
  };

  const handleUser = (user) => {
    if (!user) {
      logout();
      reject();
      return;
    }
    user
      .getIdToken()
      .then(processToken)
      .catch(reject);
  };

  getCurrentUser()
    .then(handleUser)
    .catch(reject);
});
