import { convertToFittingDataType } from '../utils/helpers';

export const getItem = (key, useSessionStorage = false) => {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  return convertToFittingDataType(storage.getItem(key));
};

export const saveItem = (key, value, useSessionStorage = false) => {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  const newValue = typeof value === 'string' ? value : JSON.stringify(value);
  return storage.setItem(key, newValue);
};

export const removeItem = (key, useSessionStorage = false) => {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  storage.removeItem(key);
};

export const clearStorage = (useSessionStorage = false) => {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  storage.clear();
};
