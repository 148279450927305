import { useState, createContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const NotificationContext = createContext({});

export default function ErrorDialogContainer({ children }) {
  const DEFAULT_NOTIFICATION_PROPS = {
    elevation: 6,
    variant: 'filled',
    severity: 'error',
    message: 'Demo Notification',
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: 5000,
  };

  const [isNotificationVisible, setNotificationVisibility] = useState(false);
  const [notificationProps, setNotificationProps] = useState(DEFAULT_NOTIFICATION_PROPS);

  const hideNotification = () => setNotificationVisibility(false);

  const showNotification = (providedProps) => {
    setNotificationProps({
      ...DEFAULT_NOTIFICATION_PROPS,
      onClose: hideNotification,
      ...providedProps,
    });
    setNotificationVisibility(true);
  };

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      <Snackbar
        open={isNotificationVisible}
        onClose={() => notificationProps.autoHideDuration && hideNotification()}
        anchorOrigin={notificationProps.anchorOrigin}
        autoHideDuration={notificationProps.autoHideDuration}
      >
        <MuiAlert
          elevation={notificationProps.elevation}
          severity={notificationProps.severity}
          variant={notificationProps.variant}
          onClose={notificationProps.showCloseOption ? notificationProps.onClose : undefined}
        >
          {notificationProps.message}
        </MuiAlert>
      </Snackbar>

      {children}
    </NotificationContext.Provider>
  );
}
